/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import Alert from "react-s-alert";
import { ModalContext } from "App";
import FormInputMobile from "modules/common/components/FormInputMobile";
import Modal from "modules/common/components/Modal";
import eyeOpen from "assets/img/open-eye.png";
import eyeClose from "assets/img/close-eye.png";
import imagePhone from 'assets/img/login/2fa.png';
import SignUpContainerModal from "modules/auth/modals/SignUpModal";
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';
import useFormValidator from 'useFormValidator';
import LoginPinInput from "modules/common/components/LoginPinInput";
import * as actions from "../actions";
import * as c from "../constants";
import ForgotPasswordModal from "./ForgotPasswordModal";
import CmsContext from "../../../CmsContext";
import MfaModal from "./MfaModal";

const form = {
  password: "",
  profile_type: "taxpayer",
  otp: "",
  login_type: "credentials",
  mobile_number: "",
  disableResendOTP: false,
  login_pin: "",
  user_id: "",
  secret: ""
};

function LoginContainer({
  clearAttemp,
  mfaLogin,
  attempData,
  mfaLoginLoading,
  serviceList,
  login,
  loginLoading
}) {
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  const [enable2fa, setEnable2fa] = useState('');

  const [formData, setFormData] = useState(form);
  const [view, setView] = React.useState(false);
  const setModal = useContext(ModalContext);
  const [isEmail, setIsEmail] = useState("mobile");

  const formValidator = useFormValidator(formData, {
    mobile_number: 'required',
    password: 'required',
  })

  const formValidatorUserId = useFormValidator(formData, {
    user_id: 'required',
    login_pin: 'required',
  })

  useEffect(() => {
    if (isEmail === 'email') {
      formValidator.onSetObjectRules({
        mobile_number: 'required|email',
        password: "required",
      })
    }
    if (isEmail === 'mobile') {
      formValidator.onSetObjectRules({
        mobile_number: 'required|mobile_number_63',
        password: "required",
      })
    }
    if (isEmail === 'user_id') {
      formValidatorUserId.onSetObjectRules({
        user_id: 'required',
        login_pin: "required",
      })
    }
  }, [isEmail]);

  const handleOnViewPassword = value => e => {
    e.preventDefault();
    setView(value);
  };

  const handleSignUpModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <SignUpContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    if (isEmail === 'email' || isEmail === 'mobile') {
      const { mobile_number, password, login_type } = formData;

      if (isEmail === 'mobile') {
        if (!mobile_number) {
          Alert.error("Mobile Number is required.");
          return false;
        }
      }

      const payload = {
        mobile_number,
        password,
        login_type
      };

      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
          login({ ...payload, email: payload.mobile_number, recaptcha: token }, (profile) => {
            // setEnable2fa(profile.is_2fa_enabled);
            // if (profile.google2fa_enable === 0) {
            //   window.location.href = "/";
            //   setModal({ isOpen: false });
            // }
            // console.log('profile', profile)
            if (profile.is_mfa_required) {
              setModal({
                isOpen: true,
                content: <MfaModal profile={profile} />,
                title: 'Multi-Factor Authentication',
                modalSize: 'modal-lg',
              });
            } else {
              Alert.success('You have successfully logged in!');
              window.location.href = "/";
              setModal({ isOpen: false });
            }
          });
        });
      });

    }

    if (isEmail === 'user_id') {
      const { user_id, login_pin, login_type } = formData;
      const payload = {
        user_id,
        login_pin,
        login_type,
      };

      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
          login({ ...payload, user_id: payload.user_id, recaptcha: token }, (profile) => {
            // setEnable2fa(profile.is_2fa_enabled);
            // if (profile.google2fa_enable === 0) {
            //   window.location.href = "/";
            //   setModal({ isOpen: false });
            // }
            // console.log('profile', profile)
            if (profile.is_mfa_required) {
              setModal({
                isOpen: true,
                content: <MfaModal profile={profile} />,
                title: 'Multi-Factor Authentication',
                modalSize: 'modal-lg',
              });
            } else {
              Alert.success('You have successfully logged in!');
              window.location.href = "/";
              setModal({ isOpen: false });
            }
          });
        });
      });
    }
  };

  const handleLogin = e => {
    e.preventDefault();

    if (isEmail === 'email' || isEmail === 'mobile') {
      const { mobile_number, password, secret } = formData;

      if (isEmail === 'mobile') {
        if (!mobile_number) {
          Alert.error("Mobile Number is required.");
          return false;
        }
      }

      const payload = {
        mobile_number,
        password,
        secret
      };

      mfaLogin({ ...payload, email: payload.mobile_number }, () => {
        setEnable2fa('');
        window.location.href = "/";
        setModal({ isOpen: false });
      });
    }

    if (isEmail === 'user_id') {
      const { user_id, login_pin, secret } = formData;
      const payload = {
        user_id,
        login_pin,
        secret
      };

      mfaLogin({ ...payload, user_id: payload.user_id }, () => {
        setEnable2fa('');
        window.location.href = "/";
        setModal({ isOpen: false });
      });
    }
  };

  const handleOpenForgotPasswordModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <ForgotPasswordModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnClose = e => {
    e.preventDefault();
    clearAttemp();
    setModal({ isOpen: false });
    setFormData({
      password: "",
      profile_type: "taxpayer",
      otp: "",
      login_type: "credentials",
      mobile_number: "",
      disableResendOTP: false,
      user_id: "",
      login_pin: ""
    });
  };

  const handleOnChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleOnChangePin = pin => {
    setFormData({
      ...formData,
      login_pin: pin
    });
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setEnable2fa('');
  };

  return (
    <Modal.Body className="p-0">
      <div className="login-container">
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="form-content w-100">

                {enable2fa === '' && (
                  <>
                    <img
                      src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                      alt=""
                      className="icon-logo"
                    />
                    <h2 className="form-title font-weight-bold mt-4 mb-0 cms-text-primary">
                      Welcome,
                    </h2>
                    <p className="form-sub-title cms-text-primary">
                      {!_.has(attempData, "is_active") && (
                        <>Login to your account</>
                      )}
                      {_.has(attempData, "is_active") &&
                        `Key in your OTP (One Time Password)`}
                    </p>

                    <form onSubmit={handleOnSubmit}>
                      <div className="form-wrapper">
                        <div className="full-group">
                          <div className="form-row">
                            <div className="login-div-buttons">
                              {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsEmail("user_id");
                                    setFormData({});
                                  }}
                                  className={`login-div-item ${isEmail === 'user_id' ? 'active' : ''}`}>
                                  <span className='login-div-span'>Username</span>
                                </button>
                              )}
                              {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsEmail("mobile");
                                    setFormData({});
                                  }}
                                  className={`login-div-item ${isEmail === 'mobile' ? 'active' : ''}`}
                                >
                                  <span className='login-div-span'>Mobile Number</span>
                                </button>
                              )}
                              {_.get(CMS_COMPONENTS, "EMAIL_LOGIN") && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsEmail("email");
                                    setFormData({});
                                  }}
                                  className={`login-div-item ${isEmail === 'email' ? 'active' : ''}`}
                                >
                                  <span className='login-div-span'>Email Address</span>
                                </button>
                              )}
                            </div>
                            <div className="form-input-group col-12 mt-2">
                              <label
                                htmlFor="mobile_number"
                                className="full-label mb-0 login-options"
                              >
                                <span>
                                  {isEmail === 'email' && ("Email ")}
                                  {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && (isEmail === 'user_id') && ("Username")}
                                  {isEmail === 'mobile' && ("Mobile Number ")}
                                  {(isEmail === 'email' || isEmail === 'mobile')
                                    && (
                                      formValidator.renderMessage('mobile_number')
                                    )
                                  }
                                  {(_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (formValidatorUserId.renderMessage('user_id')))}
                                </span>
                              </label>

                              {isEmail === 'email' && _.get(CMS_COMPONENTS, "EMAIL_LOGIN") && (
                                <>
                                  <input
                                    className="full-input"
                                    type="email"
                                    name="mobile_number"
                                    value={_.get(formData, "mobile_number") || ""}
                                    autoComplete="off"
                                    required
                                    onChange={handleOnChange}
                                  />
                                </>
                              )}

                              {isEmail === 'mobile' && (
                                <>
                                  <FormInputMobile
                                    disableDropdown={false}
                                    autoComplete="off"
                                    type="tel"
                                    name="mobile_number"
                                    onChange={setFormData}
                                    value={_.get(formData, "mobile_number") || ""}
                                  />
                                </>
                              )}
                              {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                                <>
                                  <input
                                    className="full-input"
                                    type="text"
                                    name="user_id"
                                    value={_.get(formData, "user_id") || ""}
                                    autoComplete="off"
                                    required
                                    onChange={handleOnChange}
                                  />
                                </>
                              )}
                            </div>
                            {isEmail === 'email' && (
                              <div className="form-input-group col-12 prepend-form-right">
                                <label htmlFor="name" className="full-label mb-0">
                                  Password&nbsp;{formValidator.renderMessage('password')}
                                </label>
                                <input
                                  className="full-input"
                                  type={view ? "text" : "password"}
                                  name="password"
                                  value={_.get(formData, "password") || ""}
                                  required
                                  onChange={handleOnChange}
                                />
                                <button
                                  type="button"
                                  onClick={handleOnViewPassword(!view)}
                                  className="btn btn-default rounded-circle p-0 mr-1"
                                >
                                  <img src={`${!view ? eyeClose : eyeOpen}`} alt="" />
                                </button>
                              </div>
                            )}
                            {isEmail === 'mobile' && (
                              <div className="form-input-group col-12 prepend-form-right">
                                <label htmlFor="name" className="full-label mb-0">
                                  Password&nbsp;{formValidator.renderMessage('password')}
                                </label>
                                <input
                                  className="full-input"
                                  type={view ? "text" : "password"}
                                  name="password"
                                  value={_.get(formData, "password") || ""}
                                  required
                                  onChange={handleOnChange}
                                />
                                <button
                                  type="button"
                                  onClick={handleOnViewPassword(!view)}
                                  className="btn btn-default rounded-circle p-0 mr-1"
                                >
                                  <img src={`${!view ? eyeClose : eyeOpen}`} alt="" />
                                </button>
                              </div>
                            )}
                            {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                              <div className="form-input-group mt-2 ml-2">
                                <label
                                  htmlFor="mobile_number"
                                  className="full-label mb-0 login-options"
                                >
                                  4-Digit PIN&nbsp;{formValidatorUserId.renderMessage('login_pin')}
                                </label>
                              </div>
                            )}
                          </div>
                          {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                            <>
                              <div className="form-wrapper">
                                <LoginPinInput onChange={handleOnChangePin} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {isEmail === 'email' && (
                        <>
                          <Link
                            to="/"
                            onClick={handleOpenForgotPasswordModal}
                            className="forgot-password text-right mt-2 d-block cms-text-primary"
                          >
                            Forgot Password
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-primary w-100 mt-4 cms-bg-color-primary cms-border-color-primary"
                            disabled={loginLoading || !formValidator.response.is_passed}
                          >
                            {loginLoading && <i className="fa fa-spin fa-spinner" />}{" "}
                            Login
                          </button>
                        </>
                      )}
                      {isEmail === 'mobile' && (
                        <>
                          <Link
                            to="/"
                            onClick={handleOpenForgotPasswordModal}
                            className="forgot-password text-right mt-2 d-block cms-text-primary"
                          >
                            Forgot Password
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-primary w-100 mt-4 cms-bg-color-primary cms-border-color-primary"
                            disabled={loginLoading || !formValidator.response.is_passed}
                          >
                            {loginLoading && <i className="fa fa-spin fa-spinner" />}{" "}
                            Login
                          </button>
                        </>
                      )}
                      {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-100 mt-4 cms-bg-color-primary cms-border-color-primary"
                            disabled={loginLoading || !formValidatorUserId.response.is_passed}
                          >
                            {loginLoading && <i className="fa fa-spin fa-spinner" />}{" "}
                            Login
                          </button>
                        </>
                      )}

                      <Link
                        to="/"
                        onClick={handleSignUpModal}
                        className="sign-up mt-3 d-block cms-text-primary"
                      >
                        Don’t have account yet? <strong>Sign up here.</strong>
                      </Link>
                    </form>
                  </>
                )}
                {enable2fa === 1 && (
                  <>
                    <div>
                      <button
                        href="/"
                        onClick={handleGoBack}
                        type="button"
                        className="btn btn-link common-back"
                      >
                        <i className="fas fa-chevron-left icon font-weight-bold" /> Back
                      </button>
                    </div>
                    <div className="form-content w-100">
                      <img
                        src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                        alt=""
                        className="icon-logo"
                      />
                    </div>
                    <div className="step-css text-center mt-3 mb-3 font-weight-bold">
                      2-Step Verification
                    </div>
                    <div className="text-center mb-3">
                      <img
                        src={imagePhone}
                        alt="Local Government System"
                        style={{ width: 250, height: 250 }}
                      />
                    </div>
                    <div className="col-12">
                      <div className="sub-text mb-2 text-center">You&apos;ve asked us to require a 6-digit login code when anyone tries to access your account from a new device or browser.</div>
                    </div>
                    <div className="col-12">
                      <div className="cms-text-primary label-authenticate">Enter a 6-digit code from your code generator or third-party app below.</div>
                    </div>
                    <form className="mt-3" onSubmit={handleLogin}>
                      <div className="form-group col-12">
                        <label className="password-label">
                          Authentication Code
                        </label>
                        <InputMask
                          placeholder='Enter Authentication Code'
                          className='form-control'
                          maskChar=' '
                          mask='999999'
                          autoComplete='none'
                          id='secret'
                          name='secret'
                          onChange={handleOnChange}
                          value={
                            _.get(formData, "secret") ||
                            ""
                          }
                        />
                      </div>
                      <div className="col-12 mb-5">
                        <button disabled={mfaLoginLoading || _.isEmpty(formData.secret)} type="submit" className="btn btn-primary cms-bg-color-primary cms-outline-primary btn-block">Submit</button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                  serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
                {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                  serviceList &&
                  serviceList.map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
              </div>
            </div>
          </div>
        </div>
      </div >
    </Modal.Body>
  );
}

LoginContainer.propTypes = {
  mfaLogin: PropTypes.instanceOf(Function).isRequired,
  mfaLoginLoading: PropTypes.bool.isRequired,
  attempData: PropTypes.instanceOf(Object).isRequired,
  clearAttemp: PropTypes.instanceOf(Function).isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired,
  login: PropTypes.instanceOf(Function).isRequired,
  loginLoading: PropTypes.bool.isRequired,
};

const removeENews = res => {
  const newArr = res.filter(d => {
    return (
      d.attributes.name !== "E-Inquiry" &&
      d.attributes.name !== "E-Wallet" &&
      d.attributes.name !== "E-Help" &&
      d.attributes.name !== "E-Government and E-Services"
    );
  });
  return newArr;
};

const mapStateToProps = ({ api }) => ({
  mfaLoginLoading: _.get(api, "loading", []).indexOf(c.MFA_LOGIN) > -1,
  attempData: _.get(api, `${c.MFA_LOGIN}.item`) || {},
  loginLoading: _.get(api, "loading", []).indexOf(c.LOGIN) > -1,
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || [])
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(LoginContainer);
