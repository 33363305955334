import React, { useContext, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import _ from "lodash";
import { connect } from "react-redux";
import Modal from "modules/common/components/Modal";

// eslint-disable-next-line no-unused-vars
import { set, removeItem } from "session";
import { ModalContext } from "App";
import DOMPurify from 'dompurify';
import PinInput from "modules/common/components/PinInput";
import CountdownComponent from "modules/common/components/CountdownComponent";
import FormInputMobile from "modules/common/components/FormInputMobile";
import LoginContainerModal from "modules/auth/modals/LoginContainerModal";
import PropTypes from "prop-types";
import useFormValidator from 'useFormValidator';
import eyeOpen from "assets/img/open-eye.png";
import eyeClose from "assets/img/close-eye.png";
import AuthenticatorActive from "assets/img/mfa/Authenticator-Icon-Active.png"
import Authenticator from "assets/img/mfa/Authenticator-Icon.png"
import EmailActive from "assets/img/mfa/Email-Icon-Active.png"
import Email from "assets/img/mfa/Email-Icon.png"
import SmsActive from "assets/img/mfa/Sms-Icon-Active.png"
import Sms from "assets/img/mfa/Sms-Icon.png"

import * as actions from "../actions";
import * as c from "../constants";
import CmsContext from "../../../CmsContext";

const form = {
  email: "",
  password: "",
  password_confirmation: "",
  mobile_number: "",
  last_name: "",
  first_name: "",
  is_resident: 0,
  terms_of_service: 0,
  data_privacy: 0,
};

function SignUpContainer({
  isLoading,
  signUp,
  history,
  register,
  resendOTP,
  resending,
  serviceList,
  signupFormData,
  buttonLabel,
}) {
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_CONTENTS = cmsContext.findByType("CONTENTS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  const [hidden, setHidden] = useState({ hidden: false });
  const [hiddenConfirm, setHiddenConfirm] = useState({ hiddenConfirm: false });

  const [formMobile, setFormMobile] = React.useState({
    mobile_number: ""
  });
  const [rand, setRand] = useState(_.random(1, 1000));
  const [formData, setFormData] = useState(() => {
    if (!_.isEmpty(signupFormData)) return signupFormData
    return form
  });

  const [showOTP, setShowOTP] = useState(() => {
    return JSON.parse(localStorage.getItem("showOTP")) || false
  });

  const [mfa, setMfa] = useState();
  const [method, setMethod] = useState('');

  const setModal = useContext(ModalContext);
  const [formType, setFormType] = useState("signup");

  const formValidator = useFormValidator(formData, {
    first_name: 'required|text_vapt',
    last_name: 'required|text_vapt',
    password: 'required|password',
    password_confirmation: 'required|match:password',
    terms_of_service: 'bool_true',
  })

  const mobileNumberValidator = useFormValidator(formMobile, {
    mobile_number: 'required|mobile_number_63',
  })

  const handleOpenAuthModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <LoginContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleCheckChange = () => {
    setFormData({
      ...formData,
      terms_of_service: !_.get(formData, "terms_of_service")
    });
  };
  const handleOnChange = e => {
    const value =
      e.target.name === "is_resident" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
        const args = {
          ...formData,
          mobile_number: formMobile.mobile_number,
          recaptcha: token
        };
        register(
          args,
          () => {
            setRand(_.random(1, 1000));
            setShowOTP(true);
            set("showOTP", JSON.stringify(true));
            set("signupFormData", JSON.stringify(args))
          }
        );
      });
    });
  };

  const handleOTP = e => {
    e.preventDefault();

    const { password, first_name, last_name, otp } = formData;


    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
        const payload = {
          mobile_number: formMobile.mobile_number ? formMobile.mobile_number : formData.mobile_number,
          pin: otp,
          password,
          first_name,
          last_name,
          recaptcha: token,
        };
        signUp(payload, () => {
          history.push("/");
          setModal({ isOpen: false });
          removeItem("showOTP");
          removeItem("signupFormData");
        });
      });
    });
  };

  const handleOnchangePin = otp => {
    setFormData({
      ...formData,
      otp
    });
  };

  const handleResendOtp = e => {
    e.preventDefault();

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
        const args = {
          mobile_number: formMobile.mobile_number ? formMobile.mobile_number : formData.mobile_number,
          recaptcha: token
        };

        resendOTP(args, () => {
          setRand(_.random(1, 1000));
          setShowOTP(true);
        });
      });
    });
  };

  const cancelOTPv2 = btnLabel => {
    if (btnLabel === "sign-in") {
      setModal({
        isOpen: true,
        content: <LoginContainerModal />,
        title: null,
        modalSize: "modal-lg",
        hideHeader: true,
        isCentered: true
      });
    }

    if (btnLabel === "sign-up") {
      setModal({
        idOprn: true,
        content: <SignUpContainer />,
        title: null,
        modalSize: "modal-lg",
        hideHeader: true,
        isCentered: true,
      })

      removeItem("signupFormData");
    }

    setShowOTP(false)
    removeItem("showOTP");
  }

  const onCancelOTPS = () => {
    // go back to sign-in form and cancel current sign-up
    if (buttonLabel === 'sign-in') {
      setModal({
        isOpen: true,
        content: <LoginContainerModal />,
        title: null,
        modalSize: "modal-lg",
        hideHeader: true,
        isCentered: true
      });

      removeItem("signupFormData");
    }
    setShowOTP(false)
    removeItem("showOTP");
  }

  const secretMobileNumber = mobile_number => {
    return [...mobile_number].map((item, i) => (i > 1 && i < 9 ? "*" : item));
  };

  const handleOnClose = e => {
    if (e) e.preventDefault();
    setModal({ isOpen: false });

    if (!showOTP) {
      removeItem("signupFormData");
    }
  };

  const onChangeAgreement = (name, value) => e => {
    e.preventDefault();
    setFormData({
      ...formData,
      [name]: value
    });
    setFormType("signup");
  };

  const cancelOTPv2Button = (btnLabel) => {

    return (
      <button
        type="button"
        style={{ cursor: "pointer" }}
        onClick={() => cancelOTPv2(btnLabel)}
        className="btn btn-link px-1 font-weight-bold btn-no-focus-default"
      >
        {btnLabel === "sign-up" ? "Sign Up" : "Sign In"}
      </button>
    )
  }

  const METHODS = [
    {
      src: Authenticator,
      srcActive: AuthenticatorActive,
      label: 'APP Authenticator',
      label2: 'Google Authenticator',
      value: 'authenticator',
      URL: '/login/authenticator',
      description: 'Enter a single-use code from your Google Authenticator.',
    },
    {
      src: Sms,
      srcActive: SmsActive,
      label: 'SMS Authentication',
      label2: 'SMS',
      value: 'mobile',
      URL: '/login/auth-otp',
  
      description: 'Enter a single-use code from your registered mobile number.',
    },
    {
      src: Email,
      srcActive: EmailActive,
      label: 'Email Authentication',
      label2: 'Email',
      value: 'email',
      URL: '/login/auth-otp',
      description: 'Enter a single-use code from your registered email address.',
    },
  ];

  const handleSelectedMfa = e => {
    e.preventDefault();
    setMfa(method)
  }

  const onMethodClick = (row) => {
    setMethod(row?.value);
  };

  return (
    <Modal.Body className="p-0">
      <div className="login-container">
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="form-content w-100">
                <img
                  src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                  alt=""
                  className="icon-logo"
                />
                {!showOTP && !mfa && (
                  <form onSubmit={handleOnSubmit}>
                    {formType === "signup" && (
                      <>
                        <h2 className="form-title font-weight-bold mt-4 mb-0 cms-text-primary">
                          Hi,
                        </h2>
                        <p className="form-sub-title cms-text-primary">
                          Create your account here
                          {showOTP && (
                            <button type="button" onClick={onCancelOTPS} className="btn btn-link p-0 m-0 font-weight-bold text-primary">
                              {buttonLabel === "sign-up" ? <i> or go back to sign up another account.</i> : <i> or sign in to another account.</i>}
                            </button>
                          )}
                        </p>
                        <div className="form-wrapper mt-4">
                          <div className="full-group">
                            <div className="form-row">
                              <div className="form-input-group col-6">
                                <label
                                  htmlFor="first_name"
                                  className="full-label mb-0"
                                >
                                  First Name&nbsp;{formValidator.renderMessage('first_name')}
                                </label>
                                <input
                                  className="full-input text-uppercase"
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  onChange={handleOnChange}
                                  value={_.get(formData, "first_name") || ""}
                                  placeholder="ex. Juan"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                              <div className="form-input-group col-6">
                                <label
                                  htmlFor="last_name"
                                  className="full-label mb-0"
                                >
                                  Last Name&nbsp;{formValidator.renderMessage('last_name')}
                                </label>
                                <input
                                  className="full-input text-uppercase"
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  onChange={handleOnChange}
                                  value={_.get(formData, "last_name") || ""}
                                  placeholder="ex. Dela Cruz"
                                  autoComplete="off"
                                  required
                                />
                              </div>

                              <div className="form-input-group col-12">
                                <label
                                  htmlFor="email"
                                  className="full-label mb-0"
                                >
                                  Mobile Number&nbsp;{mobileNumberValidator.renderMessage('mobile_number')}
                                </label>
                                <FormInputMobile
                                  disableDropdown={false}
                                  autoComplete="off"
                                  type="tel"
                                  name="mobile_number"
                                  onChange={setFormMobile}
                                  value={formMobile.mobile_number || ""}
                                />
                              </div>
                              <div className="form-input-group col-12">
                                <label
                                  htmlFor="password"
                                  className="full-label mb-0"
                                >
                                  Password&nbsp;{formValidator.renderMessage('password')}
                                </label>
                                <div className="inner-addon right-addon right-addon-button">
                                  <input
                                    type={hidden ? 'password' : 'text'}
                                    placeholder="Password"
                                    onChange={handleOnChange}
                                    value={_.get(formData, 'password') || ''}
                                    name="password"
                                    id="loginPassword"
                                    className="form-control form-control-lg form-control-icon"
                                  />
                                  <button
                                    type="button"
                                    tabIndex={-1}
                                    className="btn btn-right"
                                    onClick={() => setHidden(!hidden)}
                                  >
                                    <img src={`${hidden ? eyeClose : eyeOpen}`} alt="" />
                                  </button>
                                </div>
                              </div>
                              <div className="form-input-group col-12 mt-2">
                                <label
                                  htmlFor="password"
                                  className="full-label mb-0"
                                >
                                  Confirm Password&nbsp;{formValidator.renderMessage('password_confirmation')}
                                </label>
                                <div className="inner-addon right-addon right-addon-button">
                                  <input
                                    type={hiddenConfirm ? 'password' : 'text'}
                                    placeholder="Confirm Password"
                                    onChange={handleOnChange}
                                    value={_.get(formData, 'password_confirmation') || ''}
                                    name="password_confirmation"
                                    id="password_confirmation"
                                    className="form-control form-control-lg form-control-icon"
                                  />
                                  <button
                                    type="button"
                                    tabIndex={-1}
                                    className="btn btn-right"
                                    onClick={() => setHiddenConfirm(!hiddenConfirm)}
                                  >
                                    <img src={`${hiddenConfirm ? eyeClose : eyeOpen}`} alt="" />
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 cms-bg-color-primary-ligt mt-2">
                                <div className="font-weight bold mt-3 p-3" role="alert">
                                  <ul className="mb-0 cms-text-primary">
                                    <li >Minimum of 8 Characters</li>
                                    <li>At least one uppercase and lowercase letter</li>
                                    <li>At least one symbol</li>
                                    <li>At least one number</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="text-center col-12">
                                <input
                                  onChange={handleCheckChange}
                                  required
                                  checked={_.get(
                                    formData,
                                    "terms_of_service"
                                  )}
                                  type="checkbox"
                                  className="iagree-check"
                                />
                                <span className="iagree-span">
                                  I agree with the
                                </span>
                                <button
                                  onClick={() => setFormType("tos")}
                                  type="button"
                                  className="btn btn-sm btn-link pt-1 iagree cms-text-primary"
                                >
                                  Terms and Conditions
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary w-100 mt-3 cms-bg-color-primary cms-outline-primary"
                          disabled={isLoading || !formValidator.response.is_passed || !mobileNumberValidator.response.is_passed}
                          style={{
                            cursor: isLoading ? "not-allowed" : "pointer"
                          }}
                        >
                          {isLoading && <i className="fa fa-spin fa-spinner" />}{" "}
                          Sign up
                        </button>
                        <Link
                          to="/"
                          onClick={handleOpenAuthModal}
                          className="sign-up mt-3 d-block cms-text-primary"
                        >
                          Already have an account?{" "}
                          <strong>Login now</strong>
                        </Link>
                      </>
                    )}
                    {formType === "tos" && (
                      <div className="mt-2">
                        <div className="card-header bg-white">
                          <h2 className="text-primary cms-text-primary font-weight-bold">
                            Terms of Service
                          </h2>
                        </div>
                        <div
                          className="card-body animated fadeIn cms-bg-color-primary-ligt"
                          style={{ height: 400, overflow: "auto" }}
                        >
                          <div
                            className="m-3 mb-2 cms-text-primary"
                            /* eslint-disable */ // only disable the warning message for eslint
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(_.get(CMS_CONTENTS, 'TERMS_AND_CONDITION')),
                            }}
                          />
                        </div>
                        <div className="card-footer bg-white text-center">
                          <button
                            onClick={onChangeAgreement("terms_of_service", 1)}
                            type="button"
                            className="btn btn-sm btn-success"
                          >
                            <i className="fa fa-check" />
                            &nbsp;Agree
                          </button>
                          &nbsp;
                          <button
                            onClick={onChangeAgreement("terms_of_service", 0)}
                            type="button"
                            className="btn btn-sm btn-danger"
                          >
                            <i className="fa fa-times" />
                            &nbsp;Disagree
                          </button>
                        </div>
                      </div>
                    )}
                    {formType === "dp" && (
                      <div className="">
                        <div className="card-header bg-white text-center">
                          <h3 className="text-primary cms-text-primary">
                            Data Privacy Policy
                          </h3>
                        </div>
                        <div
                          className="card-body animated fadeIn"
                          style={{ height: 400, overflow: "auto" }}
                        >
                          <div
                            className="m-3  mb-2"
                            /* eslint-disable */ // only disable the warning message for eslint
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(_.get(CMS_CONTENTS, 'PRIVACY_POLICY')),
                            }}
                          />
                        </div>
                        <div className="card-footer bg-white text-center">
                          <button
                            onClick={onChangeAgreement("data_privacy", 1)}
                            type="button"
                            className="btn btn-sm btn-success"
                          >
                            <i className="fa fa-check" />
                            &nbsp;Agree
                          </button>
                          &nbsp;
                          <button
                            onClick={onChangeAgreement("data_privacy", 0)}
                            type="button"
                            className="btn btn-sm btn-danger"
                          >
                            <i className="fa fa-times" />
                            &nbsp;Disagree
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                )}

                {showOTP && (
                  <form className="mt-3" onSubmit={handleOTP}>
                    <div className="form-wrapper">
                      <div
                        className="alert alert-primary text-center"
                        role="alert"
                      >
                        We sent your One Time Password (OTP) to{" "}
                        <i className="text-success font-weight-bold">
                          {_.get(formMobile, "mobile_number")
                            ? secretMobileNumber(
                              _.get(formMobile, "mobile_number")
                            ) : secretMobileNumber(_.get(formData, 'mobile_number'))}
                        </i>
                      </div>
                      <PinInput onChange={handleOnchangePin} />
                    </div>
                    <div className="row justify-content-center mt-3">
                      <CountdownComponent key={`countdown-${rand}`} />
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <button
                          onClick={handleResendOtp}
                          type="submit"
                          className="btn btn-block btn-danger mt-4"
                        >
                          {resending && <i className="fa fa-spin fa-spinner" />}{" "}
                          Resend OTP
                        </button>
                      </div>
                      <div className="col-12 col-md-6">
                        <button
                          type="submit"
                          className="btn btn-primary mt-4 btn-block cms-bg-color-primary"
                          disabled={isLoading || _.isEmpty(formData.otp)}
                        >
                          {isLoading && <i className="fa fa-spin fa-spinner" />}{" "}
                          Submit
                        </button>
                      </div>
                      {
                        showOTP && (
                          <div className="">
                            <span>go back to </span>
                            {cancelOTPv2Button("sign-up")}
                            <span>page or </span>
                            {cancelOTPv2Button("sign-in")}
                            <span>to existing account.</span>
                          </div>
                        )
                      }
                    </div>
                  </form>
                )}
                {mfa && (
                  <form onSubmit={handleSelectedMfa}>
                    <div className="mfa-container">
                      <div className="mfa-title">
                        <div className="mfa-header cms-text-primary my-2">Multi-Factor Authentication</div>
                        <div className="mfa-detail mb-2">
                          Add an extra layer of security to your account by enabling Multi-Factor Authentication (MFA). 
                          Select one of the following methods to complete login:
                        </div>
                        <div className="mfa-detail2">
                          Select one of the following methods to complete login: 
                        </div>
                      </div>
                    </div>
                    {METHODS.map((item, index) => {
                      return (
                        <button
                          key={index}
                          className={`btn mt-4 btn-block  ${item.value === method ? 'cms-outline-primary' : ''}`}
                          onClick={() => onMethodClick(item)}
                          type="button"
                        >
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="">
                              <img src={item.value === method ? item.srcActive : item.src} alt="auth" className="h-auto mx-auto" />
                            </div>
                            <div className="ml-3 text-left">
                              <div className="font-weight-bold cms-text-primary">{item.label}</div>
                              <div className="font-weight-normal" >
                                {item.description}
                              </div>
                            </div>
                          </div>
                        </button>
                      );
                    })}
                    <button
                      type="submit"
                      className="btn btn-primary mt-4 btn-block cms-bg-color-primary"
                    >
                      Submit
                    </button>

                    {
                      mfa && (
                        <div className="">
                          <span>go back to </span>
                          {cancelOTPv2Button("sign-up")}
                          <span>page or </span>
                          {cancelOTPv2Button("sign-in")}
                          <span>to existing account.</span>
                        </div>
                      )
                    }
                  </form>
                )}
                {mfa === 'authenticator' && (
                  <div>
                    <div className="mfa-container">
                      <div className="mfa-title">
                        <div className="mfa-header cms-text-primary my-2">Multi-Factor Authentication</div> 
                        <div className="mfa-detail2 cms-text-primary">
                          Please follow the steps below to enable MFA via Authenticator App:
                        </div>
                        <ol className="mfa-detail2 font-weight-bold p-3">
                        <li>
                          <p className="mb-1">Download an authenticator app</p>
                          <p className="mfa-detail mb-2">
                            Install an authenticator app to your phone. You&apos;ll use this app to generate verification codes when logging in. Here are some options:
                          </p>
                          <div className="d-flex my-2">
                            <div>
                              image
                            </div>
                            <div>
                              Google Authenticator
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <div>
                              image
                            </div>
                            <div>
                              Microsoft Authenticator
                            </div>
                          </div>
                        </li>
                        <li>
                          <p>Scan QR Code with authenticator app</p>
                          <p className="mfa-detail mb-2">
                            Open your downloaded authenticator app on your phone and scan the QR code. This will link the app to your account.
                          </p>
                          <div className="d-flex my-2">
                            <div>
                              image
                            </div>
                            <div>
                              <p>You can also enter this setup key:</p>
                              <p>key</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <p>
                            Enter verification code
                          </p>
                          <p className="mfa-detail mb-2">
                            After scanning the QR code or entering the setup key above, your authenticator app will generate a verification code. Enter this code below to verify your setup.
                          </p>
                          <div className="form-wrapper mt-4">
                            <div className="full-group">
                              <div className="form-row">
                                <div className="form-input-group col-12">
                                  <input
                                    className="full-input text-uppercase"
                                    type="text"
                                    name="last_name"
                                    onChange={handleOnChange}
                                    value={_.get(formData, "last_name") || ""}
                                    placeholder="Enter Confirmation Code"
                                    // autoComplete="off"
                                    required
                                  />
                                </div>
                                <button 
                                  type='button'
                                  className="btn btn-primary w-100 mt-3 cms-bg-color-primary cms-outline-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </div> 
                          </div>                         
                        </li>
                      </ol>
                      </div>
                    </div>
                  </div>
                )}
                {mfa === 'mobile' && (
                  <div>
                    <div className="mfa-container">
                      <div className="mfa-title">
                        <div className="mfa-header cms-text-primary my-2">Multi-Factor Authentication</div>
                        <div className="mfa-detail mb-2">
                          To continue setting up SMS Authentication for your account, please confirm your mobile number below:
                        </div>
                        <div className="mfa-detail2">
                          To continue setting up SMS Authentication for your account, please confirm your mobile number below:
                        </div>
                      </div>
                    </div>
                  <div>
                    <label className="form-label">Mobile Number</label>
                    <FormInputMobile
                      disableDropdown={false}
                      autoComplete="off"
                      type="tel"
                      name="mobile_number"
                      onChange={setFormData}
                      value={_.get(formData, "mobile_number") || ""}
                    />
                  </div>
                  <div>
                    <div className="col-12 mb-5">
                      <button type="button" className="btn btn-primary cms-bg-color-primary cms-outline-primary btn-block">Submit</button>
                    </div>
                  </div>
                </div>
                )}
                {mfa === 'email' && (
                  <div>
                    <div className="mfa-container">
                      <div className="mfa-title">
                        <div className="mfa-header cms-text-primary my-2">Multi-Factor Authentication</div>
                        <div className="mfa-detail mb-2">
                          To continue setting up Email Authentication for your account, please confirm your email address below:
                        </div>
                        <div className="mfa-detail2">
                          To continue setting up Email Authentication for your account, please confirm your email address below:
                        </div>
                      </div>
                    </div>
                    <div>
                      
                    </div>
                    <div>
                      <label>Email Address</label>
                      <input
                        className="full-input"
                        type="email"
                        name="email"
                        value={_.get(formData, "email") || ""}
                        placeholder="ex. sample@email.com"
                        autoComplete="off"
                        autoFocus
                        required
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                  serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
                {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                  serviceList &&
                  serviceList.map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body >
  );
}

SignUpContainer.defaultProps = {
  signupFormData: {},
  buttonLabel: '',
}

SignUpContainer.propTypes = {
  signUp: PropTypes.instanceOf(Function).isRequired,
  resendOTP: PropTypes.instanceOf(Function).isRequired,
  register: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired,
  signupFormData: PropTypes.instanceOf(Object),
  buttonLabel: PropTypes.string,
};

const removeENews = res => {
  const newArr = res.filter(d => {
    return (
      d.attributes.name !== "E-Inquiry" &&
      d.attributes.name !== "E-Wallet" &&
      d.attributes.name !== "E-Help" &&
      d.attributes.name !== "E-Government and E-Services"
    );
  });
  return newArr;
};

const onSignupFormData = () => {
  try {
    return JSON.parse(localStorage.getItem("signupFormData")) || {};
  } catch (err) {
    return {}
  }
}

const mapStateToProps = ({ api }) => ({
  signupFormData: onSignupFormData(),
  isLoading: _.get(api, "loading", []).indexOf(c.REGISTER) > -1,
  validating: _.get(api, "loading", []).indexOf(c.SIGN_UP) > -1,
  resending: _.get(api, "loading", []).indexOf(c.RESEND_OTP) > -1,
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || [])
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(SignUpContainer);
