import billers from "assets/img/services/billers.svg";
import sss from "assets/img/services/sss.svg";
import pagibig from "assets/img/services/pagibig.svg";
import kitchen from "assets/img/services/cloud-kitchen.svg";
import shopping from "assets/img/services/e-shopping.svg";
import market from "assets/img/services/e-market.svg";
import rentals from "assets/img/services/rentals.svg";
import delivery from "assets/img/services/icn-delivery.svg";
import loans from "assets/img/services/icn-loans.svg";
import nca from "assets/img/services/icn-nca.svg";
import medical from "assets/img/services/icn-medical.svg";
// import elect from "assets/img/services/icn-elect.svg";
// import water from "assets/img/services/icn-water.svg";
// import telco from "assets/img/services/icn-telco.svg";
import philhealth from "assets/img/services/icn-philhealth.svg";
// import signal from "assets/img/services/icn-signal.svg";

export const AUTO_AUTH_ = "AUTH/auto_auth_";
export const AUTO_AUTH = "AUTH/auto_auth";
export const GET_MODULE_TYPE_LIST = "TRANSACTION/get_module_type_list";
export const GET_ARTICLES = "NEWS/get_article";
export const GET_ARTICLE_INFO = "NEWS/get_article_info";

export const FEATURED_ARTICLES = "NEWS/featured_articles";
export const FEATURED_VIDEOS = "NEWS/featured_videos";
export const GET_SERVICES = "SERVICES/get_services";

export const E_BUSINESS = [
  {
    attributes: {
      cms_code: 'PRIVATE_BILLERS',
      sequence: 1,
      name: "Private Billers",
      photo: billers,
      description:
        "Companies and citizens can now pay online to various private billers such as <i> internet, electricity, water, cable, etc. </i>",
    },
  },
  {
    attributes: {
      cms_code: 'SSS',
      sequence: 2,
      name: "SSS",
      photo: sss,
      description:
        "Companies and citizens can now pay SSS contributions online ",
    },
  },
  {
    attributes: {
      cms_code: 'PAG_IBIG',
      sequence: 3,
      name: "PAGIBIG",
      photo: pagibig,
      description:
        "Companies and citizens can now pay Housing Loan Payment and monthly amortizations online.",
    },
  },
  {
    attributes: {
      cms_code: 'PHILHEALTH',
      sequence: 4,
      name: "Philhealth",
      photo: philhealth,
      description:
        "Companies and citizens can now pay PhilHealth Contributions online.",
    },
  },
  // {
  //   attributes: {
  //     sequence: 5,
  //     name: "Electricity",
  //     photo: elect,
  //     description: "Citizens can now pay and settle Electricity bill online.",
  //   },
  // },
  // {
  //   attributes: {
  //     sequence: 6,
  //     name: "Water",
  //     photo: water,
  //     description: "Citizens can now pay and settle Water bill online. ",
  //   },
  // },
  // {
  //   attributes: {
  //     sequence: 7,
  //     name: "Cable",
  //     photo: signal,
  //     description: "Citizens can now pay and settle utility bills  online.",
  //   },
  // },
  // {
  //   attributes: {
  //     sequence: 8,
  //     name: "Telco",
  //     photo: telco,
  //     description: "Citizens can now pay and settle utility bills  online.",
  //   },
  // },
];

export const E_COMMERCE = [
  {
    attributes: {
      cms_code: 'EKITCHEN',
      sequence: 1,
      name: "E-Kitchen",
      photo: kitchen,
      description: "Citizens can now create seamless transactions online. ",
    },
  },
  {
    attributes: {
      cms_code: 'ESHOPPING',
      sequence: 2,
      name: "E-Shopping",
      photo: shopping,
      description:
        "Citizens can shop goods or services direct from its suppliers/suppliers online.",
    },
  },
  {
    attributes: {
      cms_code: 'EMARKET',
      sequence: 3,
      name: "E-Market",
      photo: market,
      description:
        "Citizens can now shop through an online wet market shopping portal.",
    },
  },
  {
    attributes: {
      cms_code: 'EDELIVERIES',
      sequence: 4,
      name: "E-Deliveries",
      photo: delivery,
      description: "End to end ordering and delivery management platform",
    },
  },
];

export const E_GOVERNMENT = [
  {
    attributes: {
      cms_code: 'RPTAX',
      sequence: 1,
      name: "Real Property Tax (RPTAX)",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-rpts.svg",
      description:
        "An electronic Real Property Taxing System that enables easy assessment and appraisal of real property taxes and allows online payments.",
    },
  },
  {
    attributes: {
      cms_code: 'BTAX',
      sequence: 2,
      name: "Business Tax (BTAX)",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-bpls.svg",
      description:
        "An electronic Business Permit and Licensing System that allows taxpayers to pay  their Business Taxes and secure business permits online.",
    },
  },
  {
    attributes: {
      cms_code: 'CIVIL_REGISTRY',
      sequence: 5,
      name: "Local Civil Registry",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-psa.svg",
      description: "Apply online to get a copy of your Birth Certificate",
    },
  },
  {
    attributes: {
      cms_code: 'OHP',
      sequence: 3,
      name: "Occupational Permit / Health Certificates",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-senior.svg",
      description:
        "An electronic application that integrates Occupational Permit, Health Certificate and Laboratory Processses.",
    },
  },
  {
    attributes: {
      cms_code: 'CEDULA',
      sequence: 6,
      name: "Community Tax Certificate",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-cedula.svg",
      description:
        "Allows individuals and corporations to apply and pay online to secure Community Tax Certificate (CEDULA).",
    },
  },
  {
    attributes: {
      cms_code: 'OVR',
      sequence: 4,
      name: "Ordinance Violation Receipts",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icon-ovr.svg",
      description:
        "An online payment facility for motorist that has been apprehended for traffic violations within the jurisdiction of the City.",
    },
  },
  {
    attributes: {
      cms_code: 'NOV',
      sequence: 9,
      name: "Notice of Violations",
      photo: nca,
      description:
        "An online facility for motorists who violated through the NO CONTACT APPREHENSION service of the city.",
    },
  },

  {
    attributes: {
      cms_code: 'BTAX',
      sequence: 7,
      name: "Business Permit Licensing",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-rpts.svg",
      description:
        "An electronic Business Permit Licensing System that allows taxpayers to file and pay application for new and renewal of business permits online. ",
    },
  },
];

export const E_SERVICE = [
  {
    attributes: {
      cms_code: 'SOCIAL_AMELIORATION',
      sequence: 1,
      name: "Social Amelioration",
      description:
        "Citizens can now access social amelioration programs and services online",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icon-program.svg",
    },
  },
  {
    attributes: {
      cms_code: 'JOB_HIRING',
      sequence: 2,
      name: "Job Hiring",
      description:
        "Citizens can now check and access job vacancies posting from various companies online.",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icon-jobs.svg",
    },
  },
  {
    attributes: {
      cms_code: 'RENTALS',
      sequence: 3,
      name: "Rentals",
      description:
        "Citizens can now access different type of rental services online.",
      photo: rentals,
    },
  },
  {
    attributes: {
      cms_code: 'LOANS',
      sequence: 4,
      name: "Loans",
      description:
        "Citizens can now apply and access loan programs and  services online.",
      photo: loans,
    },
  },
  {
    attributes: {
      cms_code: 'MEDICAL_SERVICES',
      sequence: 5,
      name: "Medical Services",
      description:
        "Citizens can now access medical programs and  services online.",
      photo: medical,
    },
  },
];

export const E_GOVERNMENT_SMART_CITY = [
  {
    attributes: {
      cms_code: 'RPTAX',
      sequence: 1,
      name: "Real Property Tax (RPTAX)",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-rpts.svg",
      description:
        "An electronic Real Property Taxing System that enables easy assessment and appraisal of real property taxes and allows online payments.",
    },
  },
  {
    attributes: {
      cms_code: 'BTAX',
      sequence: 2,
      name: "Business Tax (BTAX)",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-bpls.svg",
      description:
        "An electronic Business Permit and Licensing System that allows taxpayers to pay  their Business Taxes and secure business permits online.",
    },
  },
  // {
  //   attributes: {
  //     cms_code: 'EBOSS',
  //     sequence: 3,
  //     name: "Electronic Business One-Stop Shop (eBoss)",
  //     photo:
  //       "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-bpls.svg",
  //     description:
  //       "An electronic Business One-Stop shop for new, renewal and change request applications.",
  //   },
  // },
];

export const E_SERVICE_SMART_CITY = [
  {
    attributes: {
      cms_code: 'CEDULA',
      sequence: 1,
      name: "Community Tax Certificate (eCedula)",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-cedula.svg",
      description:
        "Allows individuals and corporations to apply and pay online to secure Community Tax Certificate (CEDULA).",
    },
  },
  {
    attributes: {
      cms_code: 'CIVIL_REGISTRY',
      sequence: 2,
      name: "Local Civil Registry",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-psa.svg",
      description: "Apply online to get a copy of your Birth Certificate",
    },
  },
  // {
  //   attributes: {
  //     cms_code: 'OHP',
  //     sequence: 3,
  //     name: "Health Certificate (eHealth)",
  //     photo:
  //       "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-senior.svg",
  //     description:
  //       "An electronic application that integrates Occupational Permit, Health Certificate and Laboratory Processses.",
  //   },
  // },
  // {
  //   attributes: {
  //     cms_code: 'OHP',
  //     sequence: 4,
  //     name: "Occupational Permit",
  //     photo:
  //       "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-senior.svg",
  //     description:
  //       "An electronic application that integrates Occupational Permit, Health Certificate and Laboratory Processses.",
  //   },
  // },
  // {
  //   attributes: {
  //     cms_code: 'OVR',
  //     sequence: 5,
  //     name: "Ordinance Violation Receipts",
  //     photo:
  //       "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icon-ovr.svg",
  //     description:
  //       "An online payment facility for motorist that has been apprehended for traffic violations within the jurisdiction of the City.",
  //   },
  // },
];

export const E_PAYMENT = [
  {
    attributes: {
      cms_code: 'BANKS',
      sequence: 1,
      name: "Banks",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/icn-cedula.svg",
      description:
        "",
    },
  },
  {
    attributes: {
      cms_code: 'ONLINE_BANKING',
      sequence: 2,
      name: "Online Banking",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/epayment-icn-banks.svg",
      description: "",
    },
  },
  {
    attributes: {
      cms_code: 'CASH_PAYMENTS',
      sequence: 3,
      name: "Cash Payments (OTC)",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/epayment-icn-cashpayment.svg",
      description:
        "",
    },
  },
  {
    attributes: {
      cms_code: 'KIOSK',
      sequence: 4,
      name: "Kiosk",
      photo:
        "epayment-icn-kiosk.svg",
      description:
        "",
    },
  },
  {
    attributes: {
      cms_code: 'CARDS',
      sequence: 5,
      name: "Debit / Credit Cards",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/epayment-icn-debit.svg",
      description:
        "",
    },
  },
  {
    attributes: {
      cms_code: 'WALLET',
      sequence: 6,
      name: "eWallet",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/epayment-icn-eWallet.svg",
      description:
        "",
    },
    
  },
];

export const E_NEWS = [
  {
    attributes: {
      cms_code: 'ARTICLES',
      sequence: 1,
      name: "Articles",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/enews-icn-articles.svg",
      description:
        "Latest articles around the City.",
    },
  },
  {
    attributes: {
      cms_code: 'VIDEOS',
      sequence: 2,
      name: "Videos",
      photo:
        "https://citofmanila-assets.s3-ap-southeast-1.amazonaws.com/enews-icn-videos.svg",
      description: "Latest videos around the City.",
    },
  },
];
