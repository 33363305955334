import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalContext } from "App";
import PinInput from 'modules/common/components/PinInput';
import CountdownComponent from 'modules/common/components/CountdownComponent';
import CmsContext from "../../../../CmsContext";

import * as actions from '../../actions';
import * as c from '../../constants';

function MultiFactorAuthenticationEmail({ 
  email2fa, 
  setSelectedIndex, 
  history,
  resendMfaOtp,
  isLoading,
  resending,
  mfaLogin,
  token,
  profile
}) {
  const setModal = useContext(ModalContext);
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const [showOTP, setShowOTP] = useState(false);
  const [rand, setRand] = useState(_.random(1, 1000));
  const [formData, setFormData] = useState({
    email: _.get(profile, 'email')
  });

  const handleGoBack = (e) => {
    e.preventDefault();
    setSelectedIndex(0);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnchangePin = otp => {
    setFormData({
      ...formData,
      otp
    });
  };

  const secretEmail = val => {
    return [...val].map((item, i) => (i > 1 && i < 9 ? "*" : item));
  };

  const handleNext = () => {

    const args = {
      receiver_type: 'email',
      token,
    };

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((captcha_token) => {
        email2fa({ ...args, recaptcha: captcha_token }, () => {
          setShowOTP(true);
        });
      })
    })
  };

  const handleSubmitOTP = (e) => {
    e.preventDefault();

    const args = {
      secret: formData.otp,
      mfa_type: 'email_otp',
      token
    };

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((captcha_token) => {
        mfaLogin({ ...args, recaptcha: captcha_token }, () => {
          setModal({ isOpen: false });
          history.push('/');
        });
      })
    })
  };

  const handleResendMfaOtp = e => {
    e.preventDefault();

    const args = {
      receiver_type: 'email',
      token
    };

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((captcha_token) => {
        resendMfaOtp({ ...args, recaptcha: captcha_token }, () => {
          setRand(_.random(1, 1000));
          setShowOTP(true);
        });
      })
    })
  };

  return (
    <>
      {!showOTP && (
        <>
          <div className="mfa-modal">
            <img
              src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
              alt=""
              className="icon-logo mb-3"
            />
            <div className='cms-text-primary cms-outline-primary'>
              To continue setting up Email Authentication for your account, please confirm your email address below:
            </div>
            <div className="form-group mt-2">
              <label className="form-label pb-1">
                Email Address 
                {/* {validateForm.renderMessage('email')} */}
              </label>
              <input
                type="email"
                placeholder="Email Address"
                onChange={handleOnChange}
                name="email"
                value={_.get(formData, 'email') || ''}
                id="loginUsername"
                disabled
                className="form-control form-control-lg"
                style={{ border: '1px solid #D3D3D3', borderRadius: '8px' }}
              />
            </div>
            <div className="w-100 d-flex ml-auto justify-content-center">
              <button
                type="button"
                onClick={handleGoBack}
                className="mt-3 btn btn-change cms-text-primary"
              >
                <span className="font-weight-bold">
                  Change Authentication Method
                </span>
              </button>
            </div>
            {/* </div> */}
            <hr className='below-hr' />
            {/* <div className="mfa-modal"> */}
            <div className="d-flex justify-content-center">
              <button
                type="button"
                onClick={handleNext}
                disabled={formData.email === ''}
                className="mt-3 btn btn-primary cms-bg-color-primary cms-outline-primary"
              >
                <span className="font-weight-bold">
                  Continue
                </span>
              </button>
            </div>
          </div>
        </>
      )}
      {showOTP && (
        <form className="mfa-modal" onSubmit={handleSubmitOTP}>
          <img
            src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
            alt=""
            className="icon-logo"
          />
          <div className="form-header p-3 mt-2">
            <span
              className="text-color text-center"
            >
              We sent your One Time Password (OTP) to{" "}
            </span>
            <span className="email-text">
              {_.get(formData, "email")
                ? secretEmail(
                  _.get(formData, "email")
                ) : secretEmail(_.get(formData, 'email'))}
            </span>
          </div>
          <div className="col-12 mt-2">
            <PinInput onChange={handleOnchangePin} />
          </div>
          <div className="col-12 mt-2 text-center">
            <CountdownComponent key={`countdown-${rand}`} />
          </div>
          <div className='d-flex flex-column flex-md-row flex-lg-row flex-xl-row text-center'>
            <div className="col-12 col-md-6">
              <button
                onClick={handleResendMfaOtp}
                type="submit"
                className="btn btn-outline-primary mt-4 btn-block cms-text-primary cms-outline-primary"
              >
                {resending && <i className="fa fa-spin fa-spinner" />}
                <span className='mr-2 label-small'>Resend OTP</span>
              </button>
            </div>
            <div className="col-12 col-md-6">
              <button
                type="submit"
                className="btn btn-primary mt-4 btn-block cms-bg-color-primary"
                disabled={isLoading || _.isEmpty(formData.otp)}
              >
                {isLoading && <i className="fa fa-spin fa-spinner" />}
                Submit
              </button>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-center mt-2">
            <span className='mt-2 pt-0'> or </span>
            <div className="flex-column mr-2">
              <button
                type="button"
                onClick={handleGoBack}
                className="btn btn-change cms-text-primary"
              >
                <span className="font-weight-bold">
                  Change Authentication Method
                </span>
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

MultiFactorAuthenticationEmail.propTypes = {
  setSelectedIndex: PropTypes.instanceOf(Function).isRequired,
  email2fa: PropTypes.instanceOf(Function).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  resendMfaOtp: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  mfaLogin: PropTypes.instanceOf(Function).isRequired,
  token: PropTypes.string.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }) => ({
  isLoading: _.get(api, "loading", []).indexOf(c.ENABLE_2FA) > -1,
  resending: _.get(api, "loading", []).indexOf(c.RESEND_OTP) > -1,
  token: sessionStorage.getItem('temporaryToken') || '',
});


const enhance = _.flowRight([
  withRouter,
  connect(
    mapStateToProps,
    {
      ...actions,
    },
  ),
]);

export default enhance(MultiFactorAuthenticationEmail);