export const GET_TRANSACTION_HISTORY_LIST =
  "TRANSACTION-HISTORY/GET_TRANSACTION_HISTORY_LIST";
export const GET_SELECTED_TRANSACTION_HISTORY =
  "TRANSACTION-HISTORY/GET_SELECTED_TRANSACTION_HISTORY";
export const UPLOAD_PAYMENT_RECEIPT =
  "TRANSACTION-HISTORY/UPLOAD_PAYMENT_RECEIPT";
export const GENERATE_PGI = "TRANSACTION/GENERATE_PGI";
export const UPDATE_OVRS = "TRANSACTION/update_ovr";
export const CHECK = "TRANSACTION/check_ovrs";
export const UPDATE_BUSINESS_NEW_TRANSACTION =
  "TRANSACTION/update_business_new_transaction";
export const UPDATE_BUSINESS_NEW_TRANSACTION_RENEWAL =
  "TRANSACTION/update_business_new_transaction_renewal";
export const OVRS_REGENERATE_SOA = "TRANSACTION/ovrs_regenerate_soa";
export const CANCEL_BILLING =
  "TRANSACTION/CANCEL_BILLING";

// rptax
export const RPTAX_RECOMPUTE_AMOUNT = "RPTAX_RECOMPUTE_AMOUNT/rptax_recompute_amount"

export const BPLS_URI = "/api/v1/taxpayers/business_permits/{id}/soa";
// export const BPLS_E2E_URL = "/api/v1/bpls/taxpayer/business/permit/application/{id}/preview_soa" // old uri
export const BPLS_E2E_URL =
  "/api/v1/bpls/taxpayer/business/permit/transactions/{id}/preview_soa";
export const BPLS_E2E_OR_URL =
  "/api/v1/bpls/taxpayer/business/permit/transactions/{id}/preview_or";
export const BPLS_E2E_PERMIT_URL =
  "/api/v1/bpls/taxpayer/business/permit/transactions/{id}/preview_permit";

export const REGENERATE_SOA = "REGENERATE_SOA/regenerate_soa";

export const TBPLS_URI =
  "/api/temp/taxpayers/business_permits/{id}/preview_soa";
export const TBPLS_OR_URI =
  "/api/temp/taxpayers/business_permits/{id}/preview_or";
export const TBPLS_PERMIT_URI =
  "/api/temp/taxpayers/business_permits/{id}/preview_permit";
export const OVRS_URI =
  "/api/v1/taxpayers/ordinance_violation_receipts/{id}/soa";
export const NOV_URI = "/api/v1/taxpayers/notice_of_violations/{id}/eor";
export const BPLS_JSON_URI = "/api/temp/taxpayers/business_permits/{id}";
export const RPT_BUILDING_SOA =
  "/api/v1/rpt/taxpayer/building/application/{id}/preview_soa";
export const RPT_LAND_SOA =
  "/api/v1/rpt/taxpayer/land/application/{id}/preview_soa";
export const RPT_MACHINERY_SOA =
  "/api/v1/rpt/taxpayer/machinery/application/{id}/preview_soa";
export const RPTAX_CLEARANCE_OR = "/api/v1/taxpayers/rptax_clearance/{id}/or";
export const RPTAX_CLEARANCE_SOA = "/api/v1/taxpayers/rptax_clearance/{id}/soa";
export const RPTAX_CLEARANCE =
  "/api/v1/taxpayers/rptax_clearance/{id}/clearance";

// view soa RPTAX
export const REAL_PROPERTY_TAX_RPTAX_SOA =
  "/api/v1/taxpayers/real_property_taxes/billings_from_api_imports/{id}/preview_soa";
// view OR RPTAX
export const REAL_PROPERTY_TAX_RPTAX_OR =
  "/api/v1/taxpayers/real_property_taxes/billings_from_api_imports/{id}/preview_or";

export const BPLS_DOWNLOAD = "/api/v1/taxpayers/business_permits/{id}/soa";
export const TBPLS_DOWNLOAD =
  "/api/temp/taxpayers/business_permits/{id}/preview_soa";
export const TBPLS_OR_DOWNLOAD =
  "/api/temp/taxpayers/business_permits/{id}/preview_or";
export const TBPLS_PERMIT_DOWNLOAD =
  "/api/temp/taxpayers/business_permits/{id}/preview_permit";
export const OVRS_DOWNLOAD =
  "/api/v1/taxpayers/ordinance_violation_receipts/{id}/soa";
export const NOV_DOWNLOAD = "/api/v1/taxpayers/notice_of_violations/{id}/eor";
export const OVRS_JSON_URI =
  "/api/v1/taxpayers/ordinance_violation_receipts/{id}";

// view SOA EBPLS
export const EBPLS_SOA =
  "/api/v1/ebpls/taxpayer/applications/{id}/preview_soa";
export const EBPLS_SANITARY_PERMIT =
  "/api/v1/ebpls/taxpayer/applications/{id}/preview_sanitary_permit";
export const EBPLS_BUSINESS_PERMIT =
  "/api/v1/ebpls/taxpayer/applications/{id}/preview_business_permit";
  
// EBPLS Renewal - Select Quarter for generate e-soa
export const EBPLS_RENEWAL_SELECT_QUARTER =
  "EBPLS_RENEWAL/select_quarter";

// EBPLS - Generate Permit
export const EBPLS_GENERATE_PERMIT =
  "EBPLS/generate_permit";

export const EBPLS_GET_BILLING =
  "EBPLS/get_billing_renewal"

export const EBPLS_GET_TRANSACTION_HISTORY_LIST =
  "EBPLS/get_transaction_history_list"

// EBPLS - Generate ESOA
export const EBPLS_GENERATE_ESOA =
"EBPLS/generate_esoa";

export const EBPLS_GENERATE_ESOA_RENEWAL =
  "EBPLS/generate_esoa_renewal";

export const OVRS_OR_URI =
  "/api/v1/taxpayers/ordinance_violation_receipts/{id}/eor";
export const OVRS_OR_DOWNLOAD =
  "/api/v1/taxpayers/ordinance_violation_receipts/{id}/eor";

export const MISC_FEE_OR_DOWNLOAD =
  "/api/v1/taxpayers/miscellaneous_fee_payments/{id}/eor";

export const VIEW_SOA = "TRANSACTION/VIEW_SOA";
export const REQUEST_REFUND = "TRANSACTION/request_refund";

// BPLS E2E Preview
export const BPLS_E2E_PREVIEW_URL =
  "/api/v1/bpls/taxpayer/business/permit/preview_application_new";

export const GET_SELECTED_MODULE_SYSTEM =
  "TRANSACTION/get_selected_module_system";
export const HEALTH_CERTIFICATE_URI =
  "/api/v1/taxpayers/ohp/{id}/health_certificate";
export const OCCUPATIONAL_PERMIT_URL = "/api/v1/taxpayers/ohp/";
export const OHP_OR = "/api/v1/taxpayers/ohp/{id}/eor";
export const HEALTH_CERTIFICATE =
  "/api/v1/taxpayers/health_certificate/{id}/view";
export const HEALTH_CERTIFICATE_OR =
  "/api/v1/taxpayers/health_certificate/{id}/eor";
export const RPT_LAND_OR =
  "/api/v1/rpt/taxpayer/land_transactions/{id}/preview_or";

export const REAL_PROPERTY_TAX_LAND_SOA =
  "/api/v1/rpt/taxpayer/land_transactions/{id}/preview_soa";

export const CDL_OR = 
  "api/v1/taxpayers/cedula/{id}/eor";

export const OHP_INQUIRE = "OHP_INQUIRE/ohp_inquire";

export const VALID_IDS = [
  {
    label: "PASSPORT",
    value: "PASSPORT"
  },
  {
    label: "SSS",
    value: "SSS"
  },
  {
    label: "UMID",
    value: "UMID"
  },
  {
    label: "PHILHEALTH",
    value: "PHILHEALTH"
  },
  {
    label: "DRIVER's LICENSE",
    value: "DRIVER's LICENSE"
  },
  {
    label: "VOTER's ID",
    value: "VOTER's ID"
  },
  {
    label: "SENIOR CITIZEN's ID",
    value: "SENIOR CITIZEN's ID"
  },
  {
    label: "POSTAL ID",
    value: "POSTAL ID"
  },
  {
    label: "BARANGAY ID",
    value: "BARANGAY ID"
  },
  {
    label: "Authorization Letter",
    value: "Authorization Letter"
  }
];

export const FETCH_BILLING = "TBPLS/fetch_billing";
export const CONFIRM_BILLING = "TBPLS/confirm_billing";

// select quarter rptax
export const FETCH_BILLING_RPTAX = "RPTAX/fetch_billing_rptax";
export const CONFIRM_BILLING_RPTAX = "RPTAX/confirm_billing_rptax";

// select quarter e2e
export const FETCH_BILLING_E2E = "BPLS/fetch_billing_e2e";
export const CONFIRM_BILLING_E2E = "BPLS/confirm_billing_e2e";

export const GET_APPOINTMENTS = "OHP/get_appointments";
export const GET_SELECTED_MODULE_TRANSACTION_REQUIREMENTS =
  "TRANSACTION/get_selected_module_transaction_requirements";
export const RESUBMIT_TAX_CLEARANCE = "TRANSACTION/resubmit_tax_clearance";

// select year e2e
export const INQUIRE_YEAR_E2E = "BPLS/inquire_year_e2e";
export const SELECT_YEAR_E2E = "BPLS/select_year_e2e";

export const PURPOSE = [
  {
    label: "Registration of Mortgage Contract",
    value: "Registration of Mortgage Contract"
  },
  { label: "Records", value: "Records" },
  { label: "Legal", value: "Legal" },
  { label: "Consolidation/Segregation", value: "Consolidation/Segregation" },
  { label: "Building Permit", value: "Building Permit" },
  { label: "Annotation", value: "Annotation" },
  { label: "Others", value: "Others" }
];

// new E2E /SEC/CDA Registration
export const E2E_FORM_DATA = {
  // resubmit
  transaction_id: "",
  // tab 0
  kind_of_ownership: "",
  owners_gender: "",
  dti_no: "",
  sec_no: "",
  cda_no: "",
  tin_no: "",
  business_name: "",
  trade_name: "",
  incharge_first_name: "",
  incharge_middle_name: "",
  incharge_last_name: "",
  incharge_suffix_name: "",
  nationality: "",
  cellphone_no: "",
  telephone_no: "",
  email_address: "",
  // tab 1
  main_office_street: "",
  main_office_barangay_code: "",
  main_office_municipality_code: "",
  main_office_province_code: "",
  main_office_region_code: "",
  main_office_zip_code: "",
  pinned_geolocation: "",
  // tab 2
  area: "",
  no_of_male_employees: "",
  no_of_female_employees: "",
  no_of_employees: 0,
  no_of_employees_residing_manila: "",
  no_of_van: "",
  no_of_truck: "",
  no_of_motorcycle: "",
  is_same_as_business_address: "",
  street: "",
  zip_code: "",
  barangay_code: "",
  municipality_code: "",
  province_code: "",
  region_code: "",
  location_owned: 0,
  tdn_no: "",
  pin_no: "",
  is_tdn_selected: 0,
  is_pin_selected: 0,
  lessor_name: "",
  monthly_rental: "",
  with_tax_incentives: 0,
  tax_incentives_attachment_url: "",
  // tab3
  activity_type: "",
  activity_type_others: "",
  activities: [],
  // tab 4
  is_representative: 0,
  valid_id_to_present: "",
  requirements: [],
  // summary
  terms_and_conditions_accepted: ""
};

// tab3:activities
export const BUSINESS_ACTIVITIES = {
  business_line_id: "",
  line_of_business: "",
  industrial_code: "",
  service: "",
  no_of_units: "",
  capital_amount: "",
  details: {}
};

// bpls stepper default no.
export const TAB_NO = 0;

export const PAGE_TAB = "PAGE_TAB/page_tab";
