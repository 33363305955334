/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import Modal from "modules/common/components/Modal";
import PropTypes from "prop-types";
import useFormValidator from 'useFormValidator';
import emptyStateImage from 'assets/img/icn-empty-state.svg';
import CmsContext from "../../../CmsContext";
import MultiFactorAuthenticationEmail from "./mfa/MultiFactorAuthenticationEmail";
import AuthenticationSetup from "./mfa/AuthenticatorSetup";
import MultiFactorAuthenticationSms from "./mfa/MultiFactorAuthenticationSms";

import * as actions from "../actions";
import * as c from "../constants";


const form = {
  password: "",
  profile_type: "taxpayer",
  otp: "",
  login_type: "credentials",
  mobile_number: "",
  disableResendOTP: false,
  login_pin: "",
  user_id: "",
  secret: ""
};

function MfaModal({
  clearAttemp,
  serviceList,
  profile,
  // signUpData,
  preferredMfa,
  token
}) {
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  // const [enable2fa, setEnable2fa] = useState('');
  const [selectedMfa, setSelectedMfa] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [formData, setFormData] = useState(form);
  // const [view, setView] = React.useState(false);
  const setModal = useContext(ModalContext);
  const [isEmail] = useState("mobile");

  const formValidator = useFormValidator(formData, {
    mobile_number: 'required',
    password: 'required',
  })

  const formValidatorUserId = useFormValidator(formData, {
    user_id: 'required',
    login_pin: 'required',
  })

  useEffect(() => {
    if (isEmail === 'email') {
      formValidator.onSetObjectRules({
        mobile_number: 'required|email',
        password: "required",
      })
    }
    if (isEmail === 'mobile') {
      formValidator.onSetObjectRules({
        mobile_number: 'required|mobile_number_63',
        password: "required",
      })
    }
    if (isEmail === 'user_id') {
      formValidatorUserId.onSetObjectRules({
        user_id: 'required',
        login_pin: "required",
      })
    }
  }, [isEmail]);

  const handleOnClose = e => {
    e.preventDefault();
    clearAttemp();
    setModal({ isOpen: false });
    setFormData({
      password: "",
      profile_type: "taxpayer",
      otp: "",
      login_type: "credentials",
      mobile_number: "",
      disableResendOTP: false,
      user_id: "",
      login_pin: ""
    });
  };

  const handleOnClickSetting = item => e => {
    e.preventDefault();
    setSelectedMfa(false);
    setFormData({
      ...formData,
      preferred_mfa: item.preferred_mfa,
      title: item.title
    });
  };

  // const handleOnSelectedMfa = () => {
  //   setSelectedMfa(!true);
  // };

  const handleNext = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(1);
    }
  };

  const handleOnSelectedMfa = (e) => {
    e.preventDefault();
    setSelectedMfa(true);
    const args = {
      token,
      preferred_mfa: formData.preferred_mfa,
    }
    preferredMfa(args, () => {
    });
  };

  return (
    <Modal.Body className="p-0">
      <div className="login-container">
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="container-fluid">
                {(selectedIndex === 0) && (
                  <>
                    <div className="form-content w-100">
                      <img
                        src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                        alt=""
                        className="icon-logo"
                      />
                      <div className='mfa-modal'>
                        <div className="mfa-container">
                          <div className="mfa-title">
                            <div className="mfa-header cms-text-primary my-2">Multi-Factor Authentication</div>
                            <div className="mfa-detail mb-2">
                              Add an extra layer of security to your account by enabling Multi-Factor Authentication (MFA). 
                              Select one of the following methods to complete login:
                            </div>
                            <div className="mfa-detail2">
                              Select one of the following methods to complete login: 
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {c.mfaSettings.length > 0 ? (
                            c.mfaSettings
                              .map(item => {
                                return (
                                  <React.Fragment key={item.preferred_mfa}>
                                    <button
                                      key={`module-${item.preferred_mfa}`}
                                      type="button"
                                      onClick={handleOnClickSetting(item)}
                                      className="btn mt-4 btn-block"
                                    >
                                      <div className="d-flex justify-content-start align-items-center animated fadeIn">
                                        <div className="">
                                          <img src={item.preferred_mfa === formData.preferred_mfa ? item.icon_active : item.icon} alt="auth" className="h-auto mx-auto" />
                                        </div>
                                        <div className="ml-3 text-left">
                                          <div className="font-weight-bold cms-text-primary">{item.title}</div>
                                          <div className="font-weight-normal" >
                                            {item.description}
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  </React.Fragment>
                                );
                              })
                          ) : (
                            <div className="d-flex align-items-center justitfy-content-center h-100">
                              <div className="text-center mx-auto py-4">
                                <img
                                  src={emptyStateImage}
                                  className="img-responsive image-wrapper mb-4 mt-3"
                                  alt="Empty State"
                                />
                                <p className="emp-title mb-0"> No Item Found </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>       
                    <div className="d-flex flex-row flex-fill">
                      <input
                        name="change_mfa"
                        type="checkbox"
                        className="button-select"
                        checked={selectedMfa}
                        onChange={handleOnSelectedMfa}
                      />
                      <label className="mfa-detail2 ml-2 mb-0">Save my choice for future logins</label>
                    </div>
                    <div className='mfa-modal'>
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          onClick={handleNext}
                          disabled={selectedIndex === 0 && formData.preferred_mfa === null}
                          className="mt-3 btn btn-primary btn-block cms-bg-color-primary cms-outline-primary"
                        >
                          <span className="font-weight-bold">
                            Continue
                          </span>
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {(formData.preferred_mfa === 'email_otp' && selectedIndex !== 0) && (
                  <MultiFactorAuthenticationEmail profile={profile} formData={formData} setSelectedIndex={setSelectedIndex} />
                )}
                {(formData.preferred_mfa === 'google_authenticator' && selectedIndex !== 0) && (
                  <AuthenticationSetup profile={profile} mfaData={formData} setSelectedIndex={setSelectedIndex} />
                )}
                {(formData.preferred_mfa === 'sms_otp' && selectedIndex !== 0) && (
                  <MultiFactorAuthenticationSms profile={profile} formData={formData} setSelectedIndex={setSelectedIndex} />
                )}
              </div>
            </div>
                
            
            
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                  serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
                {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                  serviceList &&
                  serviceList.map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
              </div>
            </div>
          </div>
        </div>
      </div >
    </Modal.Body>
  );
}

MfaModal.defaultProps = {
  profile: {},
  // signUpData: {}
};

MfaModal.propTypes = {
  // mfaLogin: PropTypes.instanceOf(Function).isRequired,
  // mfaLoginLoading: PropTypes.bool.isRequired,
  // attempData: PropTypes.instanceOf(Object).isRequired,
  clearAttemp: PropTypes.instanceOf(Function).isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired,
  // login: PropTypes.instanceOf(Function).isRequired,
  // loginLoading: PropTypes.bool.isRequired,
  profile: PropTypes.instanceOf(Object),
  // signUpData: PropTypes.instanceOf(Object),
  preferredMfa: PropTypes.instanceOf(Function).isRequired,
  token: PropTypes.string.isRequired,
};

const removeENews = res => {
  const newArr = res.filter(d => {
    return (
      d.attributes.name !== "E-Inquiry" &&
      d.attributes.name !== "E-Wallet" &&
      d.attributes.name !== "E-Help" &&
      d.attributes.name !== "E-Government and E-Services"
    );
  });
  return newArr;
};

const mapStateToProps = ({ api }) => ({
  // mfaLoginLoading: _.get(api, "loading", []).indexOf(c.MFA_LOGIN) > -1,
  // attempData: _.get(api, `${c.MFA_LOGIN}.item`) || {},
  // loginLoading: _.get(api, "loading", []).indexOf(c.LOGIN) > -1,
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || []),
  profile: JSON.parse(sessionStorage.getItem('enableProfile')),
  token: sessionStorage.getItem('temporaryToken') || '',
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(MfaModal);
