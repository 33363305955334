import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { ModalContext } from 'App';
import mfaGoogleAuthenticator from "assets/img/mfa/MfaGoogleAuthenticator.png"
import emptyStateImage from 'assets/img/icn-empty-state.svg';
import CmsContext from "../../../../CmsContext";
import * as actions from '../../actions';

function AuthenticatorSetup({ generate2fa, setSelectedIndex, mfaLogin, history, profile, token }) {
  const setModal = useContext(ModalContext);
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const [formData, setFormData] = useState({})

  const handleOnGenerateQr = e => {
    e.preventDefault();

    generate2fa({
      email: profile.email,
      token,
    }, (data) => {
      setFormData(data.data);
    });
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setSelectedIndex(0);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnVerify = (e) => {
    e.preventDefault();
    const args = {
      ...formData,
      email: profile.email,
      token,
      mfa_type: 'google_authenticator'
    };

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((captcha_token) => {
        mfaLogin({ ...args, recaptcha: captcha_token }, () => {
          setModal({ isOpen: false });
          history.push('/');
        });
      })
    })
  };

  useEffect(() => {
    generate2fa({
      email: profile.email,
      token,
    }, (data) => {
      setFormData(data.data);
    });
  }, []);

  return (
    <div className="mfa-container">
      <img
        src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
        alt=""
        className="icon-logo mb-3"
      />
      <div className='cms-text-primary cms-outline-primary mfa-detail2'>
        Please follow the steps below to enable MFA via Authenticator App:
      </div>
      <div className='mt-3 font-weight-bold'>
        1. Download an authenticator app
      </div>
      <div className='ml-3 mfa-detail'>
        Install an authenticator app to your phone. You&apos;ll use this app to
        generate verification codes when logging in. Here are some options
        to install on your IOS/Android device:
      </div>
      <div className="ml-3 mt-2">
        <button
          type="button"
          onClick={handleOnGenerateQr}
          className='btn-outline-primary btn-sm px-3 cms-text-primary cms-outline-primary'
        >
          <img
            src={mfaGoogleAuthenticator}
            style={{ height: '20px', width: '20px' }}
            alt="MFA Google Authenticator"
          />
          <span className='mfa-detail ml-2'>
            Google Authenticator
          </span>
        </button>
      </div>
      <div className='mt-3 font-weight-bold'>
        2. Scan QR Code with authenticator app
      </div>
      <div className='ml-3 mfa-detail'>
        Open your downloaded authenticator app on your phone and scan the QR code. This will link the app to your account.
      </div>
      <div className='ml-3 mt-3 d-flex'>
        {_.get(formData, 'qr_code_url') ? (
          <>
            <div className='col-5 border rounded-sm text-center'>
              <img
                alt=""
                src={_.get(formData, 'qr_code_url')}
                style={{ height: '100px', width: '100px' }}
                className="text-center mt-2"
              />
            </div>
            <div className='col-7 border ml-2'>
              <div>
                <p className="text-center mt-3 mfa-detail">
                  You can also enter this setup key:
                </p>
              </div>
              <div>
                <p className="text-center mt-0 cms-text-primary">{`${_.get(formData, "google2fa_secret")}`}</p>
              </div>
            </div>
          </>
        ) : (
          <div className="border text-center p-4">
            <img
              src={emptyStateImage}
              className="img-responsive image-wrapper mb-4 mt-3"
              alt="Empty State"
              style={{ height: '150px', width: '150px', top: '-5px' }}
            />
          </div>
        )}
      </div>
      <div className='mt-3 font-weight-bold'>
        3. Enter verification code
      </div>
      <div className='ml-3 mfa-detail'>
        After scanning the QR code or entering the setup key above, your authenticator app
        will generate a verification code. Enter this code below
      </div>
      <div className="input-group mt-2 ml-3">
        <InputMask
          placeholder='Enter confirmation code'
          className='form-control'
          maskChar=' '
          mask='999999'
          autoComplete='none'
          id='secret'
          name='secret'
          onChange={handleOnChange}
          value={_.get(formData, "secret") || ""}
        />
        <div className="input-group-append">
          <button
            type="button"
            disabled={_.isEmpty(_.get(formData, "secret"))}
            className="btn btn-primary btn-submit cms-bg-color-primary"
            onClick={handleOnVerify}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          onClick={handleGoBack}
          className="btn btn-change cms-text-primary"
        >
          <span className="font-weight-bold">
            Change Authentication Method
          </span>
        </button>
      </div>
    </div>
  );
}

AuthenticatorSetup.propTypes = {
  generate2fa: PropTypes.instanceOf(Function).isRequired,
  setSelectedIndex: PropTypes.instanceOf(Function).isRequired,
  mfaLogin: PropTypes.instanceOf(Function).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({
  token: sessionStorage.getItem('temporaryToken') || '',
});

const enhance = _.flowRight([
  withRouter,
  connect(
    mapStateToProps,
    {
      ...actions,
    },
  ),
]);

export default enhance(AuthenticatorSetup);