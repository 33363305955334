import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import uploadcare from 'uploadcare-widget';

class DragAndDrop extends PureComponent {
  static propTypes = {
    maxSize: PropTypes.number,
    onLoading: PropTypes.instanceOf(Function),
    caption: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.instanceOf(Function).isRequired,
    isMultiple: PropTypes.bool,
    fileType: PropTypes.string,
    crop: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    onLoading: () => {},
    maxSize: 5,
    isMultiple: false,
    caption: 'JPEG. PNG. PDF. Maximum size 5MB.',
    fileType: 'image/*, application/*',
    crop: 'free',
    label: 'Click me or drag a file to upload!',
    isDisabled: false,
  };

  state = {
    isUploading: false,
    progress: 0,
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) return;
    const { isMultiple, crop } = this.props;
    try {
      uploadcare
        .openDialog(
          uploadcare[isMultiple ? 'filesFrom' : 'fileFrom'](
            'object',
            isMultiple ? [...acceptedFiles] : acceptedFiles,
          ),
          {
            publicKey: process.env.REACT_APP_UPLOADCARE_KEY,
            tabs: 'file url',
            effects: 'crop,rotate,mirror',
            crop,
            previewStep: true,
            multiple: isMultiple,
          },
        )
        .done(this.uploaded);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('File size is too large');
    }
  };

  uploaded = (data) => {
    const { isMultiple } = this.props;
    if (isMultiple) {
      this.handleDoneMultipleFiles(data);
      return;
    }
    data.progress(this.handleProgress);
    data.done(this.handleDone);
  };

  handleDoneMultipleFiles = async (data) => {
    const newData = data.files();

    const process = item => new Promise((r) => {
      item.done((file) => {
        r(file);
      });
    });

    const multipleProgress = data.promise();
    multipleProgress.progress(this.handleProgress);

    const rawFiles = await Promise.all(newData.map(i => process(i)));
    const { onChange } = this.props;
    onChange(rawFiles);
  };

  handleDone = (data) => {
    const { onChange } = this.props;
    onChange(data);
  };

  handleProgress = ({ state, progress }) => {
    if (state === 'uploading') {
      this.setState({
        isUploading: true,
        progress: Math.ceil(progress * 100),
      });
    }
    if (state === 'uploaded') {
      this.setState({
        isUploading: true,
        progress: 100,
      });
    }

    if (state === 'ready') {
      this.setState({
        isUploading: false,
        progress: 0,
      });
    }
  };

  render() {
    const {
      maxSize, caption, label, isMultiple, fileType, isDisabled
    } = this.props;

    const { isUploading, progress } = this.state;

    const Progress = () => {
      if (isUploading) {
        return (
          <div>
            <span>({progress}%)...</span>
          </div>
        );
      }
      return <span />;
    };

    return (
      <div className="bg-light text-center">
        <Dropzone
          onDrop={this.onDrop}
          minSize={0}
          maxSize={maxSize * 1048576}
          disabled={isUploading || isDisabled}
          multiple={isMultiple}
          accept={fileType}
        >
          {({
            getRootProps, getInputProps, isDragActive, rejectedFiles,
          }) => {
            const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

            return (
              <div className="drag-drop">
                <div {...getRootProps()} className="pb-3 rounded">
                  <div>
                    <h1>
                      <i className="fas fa-image fa-3x" />
                    </h1>
                    <input {...getInputProps()} />
                    <h5 style={{ fontWeight: 500 }}>
                      {isDragActive ? 'Drop your files here' : label}
                    </h5>
                    {isFileTooLarge && <div>File size is too large or file format is invalid.</div>}
                    <small>{caption}</small>
                  </div>
                  {isUploading && <Progress />}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default DragAndDrop;
