import React, { useContext } from "react";
import { get } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import CmsContext from "CmsContext";
import { ModalContext } from "App";

export default function ComingSoon() {
  const history = useHistory();
  const location = useLocation();
  const cmsContext = useContext(CmsContext);
  const setModal = useContext(ModalContext);

  const CMS_ICONS = cmsContext.findByType("ICONS");

  const getPathName = () => {
    if (location.pathname.indexOf("e-services") > -1) {
      return "E-SERVICES";
    }

    if (location.pathname.indexOf("e-commerce") > -1) {
      return "E-COMMERCE";
    }

    if (location.pathname.indexOf("e-business") > -1) {
      return "E-BILLS";
    }

    return "E-GOVERNMENT";
  };

  const handleOnClose = (e) => {
    if (e) e.preventDefault();
    setModal({ isOpen: false });

  };

  return (
    <div className="card-body py-0">
      <div className="row d-flex align-items-center">
        <button
          onClick={() => {
            if(getPathName() === 'E-GOVERNMENT'){
              handleOnClose() 
            } else {
              history.goBack()
            } 

          }}
          type="button"
          className="btn btn-link common-back cms-text-primary"
        >
          <i className="fas fa-chevron-left" />
          <span> Back </span>
        </button>
      </div>
      <div className="soon-wrapper">
        <div className="soon-title"> {getPathName()} </div>
        <img
          src={get(
            CMS_ICONS,
            `COMING_SOON_${getPathName().replace(/-/, "_")}.cdnUrl`
          )}
          alt=""
        />
      </div>
    </div>
  );
}
